var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"ID","loading":_vm.loadingState,"search":_vm.search,"multi-sort":"","footer-props":{
    showFirstLastPage: true,
    itemsPerPageText: 'Elemente pro Seite:',
    itemsPerPageAllText: 'Alle',
  }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" / "+_vm._s(items.itemsLength)+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":"","short":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.name))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","x-small":"","fab":""},on:{"click":_vm.initialize}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-reload")])],1),_c('v-divider',{attrs:{"vertical":"","inset":""}}),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","x-small":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[(_vm.editedItem !== -1)?_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))]):_vm._e()]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-switch',{scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.editedItem.finished)?_c('span',[_vm._v("Abgeschlossen")]):_c('span',[_vm._v("Nicht abgeschlossen")])]},proxy:true}]),model:{value:(_vm.editedItem.finished),callback:function ($$v) {_vm.$set(_vm.editedItem, "finished", $$v)},expression:"editedItem.finished"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"ID Firma"},model:{value:(_vm.editedItem.companyid),callback:function ($$v) {_vm.$set(_vm.editedItem, "companyid", $$v)},expression:"editedItem.companyid"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"ID Benutzer"},model:{value:(_vm.editedItem.startinguserid),callback:function ($$v) {_vm.$set(_vm.editedItem, "startinguserid", $$v)},expression:"editedItem.startinguserid"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Grund"},model:{value:(_vm.editedItem.reason),callback:function ($$v) {_vm.$set(_vm.editedItem, "reason", $$v)},expression:"editedItem.reason"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v("Abbrechen")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.save}},[_vm._v("Speichern")])],1)],1)],1)],1)]},proxy:true},{key:"item.CreatedAt",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatTimeString")(item.CreatedAt))+" ")]}},{key:"item.finishedat.Time",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatTimeString")(item.finishedat))+" ")]}},{key:"item.finished",fn:function(ref){
  var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":"","height":"0px"},model:{value:(item.finished),callback:function ($$v) {_vm.$set(item, "finished", $$v)},expression:"item.finished"}})]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"tag":"a","to":{ name: 'Evacuation', params: { evacuationID: item.ID } }}},[_c('v-icon',_vm._g({staticClass:"mr-3",attrs:{"medium":""}},on),[_vm._v(" mdi-open-in-app ")])],1)]}}],null,true)},[_c('span',[_vm._v("Evakuierung öffnen")])]),(!item.finished)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3",attrs:{"medium":""},on:{"click":function($event){return _vm.finishEvacuation(item)}}},on),[_vm._v(" mdi-check ")])]}}],null,true)},[_c('span',[_vm._v("Evakuierung abschließen")])]):_vm._e(),_c('v-icon',{staticClass:"mr-3",attrs:{"medium":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"mr-3",attrs:{"medium":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v("Aktualisieren")])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }